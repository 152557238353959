import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
const { types } = require('sharetribe-flex-sdk');
import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { Money } = types;
  const { transaction, isProvider, intl, updatedBookings, isFree, coupDataOnConfirmation, hasRefund} = props;
  const totalQuantity = updatedBookings?.pricing?.totalQuantity

  if(hasRefund) return null;

  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);
  const couponData =
    coupDataOnConfirmation ||
    transaction?.attributes?.protectedData?.couponDataForOrderConfirmationPage;

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel =
    !isFree && isProvider ? (
      <FormattedMessage id={providerTotalMessageId} />
    ) : (
      <FormattedMessage id="OrderBreakdown.total" />
    );

  let currency = updatedBookings?.bookings?.[0]?.[0]?.unitPrice?.currency || 'USD';
  const totalPrice = transaction?.attributes?.lineItems?.[0]?.lineTotal?.amount;
  let totalPrices = new Money(updatedBookings?.pricing?.totalAmount || totalPrice, currency);
  // if (isProvider && !isRefunded && totalPrices?.amount > 0) {
  //   totalPrices = new Money(totalPrices?.amount - 2500, currency);
  // }
  // const formattedTotalPrice = formatMoney(intl, new Money(totalPrice, currency));

  let totalAmount = null;
  let priceWithoutCoupon = null;
  let priceWithCoupon = null;

  updatedBookings?.bookings?.map(item => {
    priceWithoutCoupon = priceWithoutCoupon + Number(item?.amount);
  });

  priceWithoutCoupon = priceWithoutCoupon > 0 ? priceWithoutCoupon : totalPrices?.amount;

  priceWithCoupon = couponData?.couponApplied
    ? Number(priceWithoutCoupon) - Number(couponData?.couponValueSingle * totalQuantity)
    : null;


  if (isProvider && !isRefunded && priceWithoutCoupon > 0) {
    (priceWithoutCoupon = priceWithoutCoupon - 2500), currency;
  }
  if (isProvider && !isRefunded && priceWithCoupon > 0) {
    (priceWithCoupon = priceWithCoupon - 2500), currency;
  }

  let formattedPriceWithoutCoupon =
    priceWithoutCoupon > 0 ? formatMoney(intl, new Money(priceWithoutCoupon, currency)) : null;

  let formattedPriceWithCoupon =
    priceWithCoupon > 0 ? formatMoney(intl, new Money(priceWithCoupon, currency)) : null;

  totalAmount = formattedPriceWithCoupon || formattedPriceWithoutCoupon;

  const formattedTotalPrices = formatMoney(intl, totalPrices);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>
          {isFree ? 'Free' : totalAmount || formattedTotalPrices}
        </div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;

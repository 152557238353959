import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    handleApplyForDiscountModal,
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const activePage = page => {
    return currentPage === page ? css.activeLink : null;
  };

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const mySessionsLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.createListingLink, activePage('InboxPage'))}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem> */}

        <MenuItem
          key="ProfileSettingsPage"
          className={currentUserHasListings ? '' : css.disply_none}
        >
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      params={{ is_login: 'true' }}
      name="LoginPage"
      className={classNames(css.loginLink, activePage('LoginPage'))}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {/* {search} */}
      {/* {currentUser?.attributes?.profile?.metadata?.showListing && ( */}
      {/* <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink> */}
      <div>
        <NamedLink
          className={classNames(css.createListingLink, activePage('HomePage'))}
          name="HomePage"
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.homePage" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.createListingLink, activePage('SearchPage'))}
          name="SearchPage"
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.therapistDir" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.createListingLink, activePage('FreeConsultation'))}
          name="FreeConsultation"
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.bookFree" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.createListingLink, activePage('contact-us'))}
          name="CMSPage"
          params={{ pageId: 'contact-us' }}
          showSearchParams={false}
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.contactUs" />
          </span>
        </NamedLink>
        {mySessionsLink}

        <span
          className={css.applyForDiscount}
          onClick={e => {
            e.preventDefault();
            handleApplyForDiscountModal && handleApplyForDiscountModal(true);
          }}
        >
          <FormattedMessage id="TopbarDesktop.applyForDiscount" />
        </span>
      </div>
      {/* )} */}

      {profileMenu}
      {/* {signupLink} */}
      {loginLink}
      {!authenticatedOnClientSide && isAuthenticatedOrJustHydrated && (
        <div style={{ width: '64px' }}></div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;

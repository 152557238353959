/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useEffect } from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { useLocation } from 'react-router-dom';

// import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
// import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
// import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
// import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
// import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
// import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
// import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
// import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
// import LineItemRefundMaybe from './LineItemRefundMaybe';
// import LineItemTotalPrice from './LineItemTotalPrice';
// import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './OrderBreakdown.module.css';
import { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  fetchLineItemsSuccess,
  setAllLineItems,
  setSelectedTimeSlot,
} from '../../containers/ListingPage/ListingPage.duck';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import { calculateBookingTotal, formatMoney } from '../../util/currency';
import { storedData } from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';
import { PrimaryButton } from '../Button/Button';
const { types } = require('sharetribe-flex-sdk');

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    bookingData,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
    selectedTimeSlots,
    lineItems,
    saveLineItems,
    lineItemsArray,
    allLineItems = {},
    saveAllLineItems,
    selectedTimeSlot,
    setSelectedTimeSlot,
    discountedPrice,
    listType,
    listing,
    setCouponCodeFieldValue,
    handleCouponSubmit,
    showCouponForm = false,
    orderConfirmPage = false,
    invalidCouponError = false,
    setInvalidCouponError,
    setActualTotalAmount,
  } = props;

  const [couponHasValue, setCouponHasValue] = useState(true);

  const { Money } = types;
  let sessionData = [];
  if (window && window.sessionStorage && window.location.href.includes('/checkout')) {
    sessionData = storedData('CheckoutPage');
    // if (checkoutPageData) {
    //   sessionData = JSON.parse(checkoutPageData);
    // }
  }

  const customData =
    Object.keys(allLineItems)?.length > 0
      ? allLineItems
      : sessionData?.orderData?.allLineItem
      ? sessionData?.orderData?.allLineItem
      : booking
      ? { newBooking: { bookings: [{ ...booking }] } }
      : transaction && transaction?.attributes?.protectedData?.isPrebookedSelected
      ? { newBooking: { bookings: [{ ...transaction }] } }
      : {};
  const [updatedBookings, setUpdatedBookings] = useState(customData);

  const removeSlot = (indexToRemove, listType) => {
    let modifiedList = allLineItems;
    modifiedList?.[listType]?.bookings?.splice(indexToRemove, 1);
    if (modifiedList?.[listType]?.bookings?.length === 0) {
      delete modifiedList?.[listType];
    }
    if (Object.keys(modifiedList).length === 1) {
      modifiedList = {};
    } else {
      calculateBookingTotal(modifiedList);
    }
    // selectedTimeSlot.splice(indexToRemove, 1);
    saveAllLineItems({ ...modifiedList });
    setUpdatedBookings({ ...modifiedList });
    setSelectedTimeSlot([]);
  };
  // const isCustomer = userRole === 'customer';
  // const isProvider = userRole === 'provider';
  // const lineItems = transaction.attributes.lineItems;
  // const unitLineItem = lineItems?.find(
  //   item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  // );
  // Line-item code that matches with base unit: day, night, hour, item
  // const lineItemUnitType = unitLineItem?.code;

  // const hasCommissionLineItem = lineItems.find(item => {
  //   const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
  //   const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
  //   return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  // });

  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    setActualTotalAmount && setActualTotalAmount(updatedBookings?.totalPricing?.bookingAmountTotal);
  }, [updatedBookings?.totalPricing?.bookingAmountTotal]);

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  const coupDataOnConfirmation =
    transaction?.attributes?.protectedData?.couponDataForOrderConfirmationPage;
  const couponValue =
    Number(discountedPrice?.couponValueSingle) ||
    Number(coupDataOnConfirmation?.couponValueSingle) ||
    0;
  const originalTotalPrice = updatedBookings?.totalPricing?.bookingAmountTotal;
  const originalTotalQuantity = updatedBookings?.totalPricing?.bookingQuantityTotal;
  const totalCouponDiscount = couponValue * originalTotalQuantity;

  const showCouponValue = formatMoney(intl, new Money(couponValue || 0, currency));
  const totalAmount = formatMoney(
    intl,
    new Money(originalTotalPrice - totalCouponDiscount || 0, currency)
  );
  const isFree =
    updatedBookings?.[Object.keys(updatedBookings)[0]]?.freeBooking ||
    transaction?.attributes?.protectedData?.isFree ||
    false;
  const isPrebookedSelected =
    updatedBookings?.[Object.keys(updatedBookings)[0]]?.isPrebookedSelected ||
    transaction?.attributes?.protectedData?.isPrebookedSelected ||
    false;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isViewDetailOrder = queryParams?.get('detail');
  const isCouponAppliedForDetail =
    coupDataOnConfirmation?.couponApplied || queryParams?.get('coupon');
  const showOrderDetailWithCoupon =
    isViewDetailOrder === 'true' && isCouponAppliedForDetail === 'true';

  const isInboxSalePage = location.pathname.includes('/sale/');

  return (
    <div className={classes}>
      {Object.keys(updatedBookings)?.length > 0 &&
        Object.keys(updatedBookings)?.map(listingType => {
          const listingByType = updatedBookings?.[listingType];
          const currentListType =
            listingType !== 'newBooking'
              ? listingType
              : `${transaction?.attributes?.protectedData?.listType}`;
          const listingHeading = currentListType?.includes('Therapy')
            ? currentListType
            : `${currentListType} Therapy`;

          if (listingType === 'totalPricing') return null;

          return (
            <React.Fragment key={listingType}>
              <h4 className={css.listHeading}>{listingHeading}</h4>
              <LineItemBookingPeriod
                booking={listingByType?.bookings?.[0]}
                isViewDetailOrder={isViewDetailOrder}
                coupDataOnConfirmation={coupDataOnConfirmation || discountedPrice}
                couponValue={couponValue}
                // code={lineItemUnitType}
                dateType={dateType}
                timeZone={timeZone}
                selectedTimeSlots={selectedTimeSlot}
                // lineItems={lineItems}
                intl={intl}
                // isProvider={isProvider}
                userRole={userRole}
                marketplaceCurrency={currency}
                // isCustomer={isCustomer}
                marketplaceName={marketplaceName}
                transaction={transaction}
                updatedBookings={listingByType}
                removeSlot={listIndex => {
                  removeSlot(listIndex, listingType);
                }}
                listingType={currentListType}
                isFree={isFree}
                handleCouponSubmit={handleCouponSubmit}
                isPrebookedSelected={isPrebookedSelected}
              />
              <hr className={css.totalDivider} />
            </React.Fragment>
          );
        })}

      {showCouponForm && !isFree ? (
        <>
          {discountedPrice?.couponAmount > 0 ? (
            <>
              {/* <div className={css.coupon_div}>
                <div className={css.totalLabel}>
                  {<FormattedMessage id="OrderBreakdown.couponValue" />}
                  <span className={css.remove_btn} onClick={handleCouponSubmit}>
                    {discountedPrice?.showRemoveBtn ? 'Remove' : null}
                  </span>
                </div>
                <div className={css.totalPrice}>{`-${showCouponValue}`}</div>
              </div>
              <hr className={css.totalDivider} /> */}
            </>
          ) : (
            <>
              <div className={css.coupon_form_wrapper}>
                <form onSubmit={handleCouponSubmit}>
                  <input
                    id="coupon"
                    name="coupon"
                    label="Coupon"
                    placeholder="Enter coupon code"
                    onChange={event => {
                      setCouponCodeFieldValue && setCouponCodeFieldValue(event.target.value);
                      setInvalidCouponError && setInvalidCouponError(false);
                      setCouponHasValue(event.target.value.length < 1);
                    }}
                  />
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    disabled={couponHasValue}
                  >
                    {'Apply'}
                  </PrimaryButton>
                </form>
                {invalidCouponError ? (
                  <div className={css.error_wrapper}>
                    <img src="/static/icons/exclamation.svg" />
                    <span className={css.coupon_error}>invalid code</span>
                  </div>
                ) : null}
              </div>
              <hr className={css.totalDivider} />
            </>
          )}
        </>
      ) : null}
      {showOrderDetailWithCoupon ? (
        <>
          <div className={css.coupon_div}>
            <div className={css.totalLabel}>
              {<FormattedMessage id="OrderBreakdown.couponValue" />}
            </div>
            <div className={css.totalPrice}>{`-${formatMoney(
              intl,
              new Money(couponValue || 0, currency)
            )}`}</div>
          </div>
        </>
      ) : null}
      {(Object.keys(updatedBookings)?.length > 2 || discountedPrice?.couponActualValue > 0) &&
        userRole === 'customer' &&
        !isFree && (
          <>
            <hr className={css.totalDivider} />
            <div className={css.lineItemTotal}>
              <div className={css.totalLabel}>
                {<FormattedMessage id="OrderBreakdown.bookingTotal" />}
              </div>
              <div className={css.totalPrice}>{totalAmount}</div>
            </div>
          </>
        )}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    lineItems: state.ListingPage.lineItems,
    lineItemsArray: state.ListingPage.lineItemsArray,
    allLineItems: state.ListingPage.allLineItems,
    selectedTimeSlot: state.ListingPage.selectedTimeSlot,
  };
};

const mapDispatchToProps = dispatch => ({
  saveLineItems: params => dispatch(fetchLineItemsSuccess(params)),
  saveAllLineItems: params => dispatch(setAllLineItems(params)),
  setSelectedTimeSlot: params => dispatch(setSelectedTimeSlot(params)),
});

const OrderBreakdown = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;

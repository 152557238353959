import Decimal from 'decimal.js';
import { convertMoneyToNumber, formatMoney } from './currency';
const { types } = require('sharetribe-flex-sdk');

const getTherapyType = therapyType => {
  let listing_type = therapyType?.includes('has_all')
    ? therapyType?.split('has_all:')?.[1]
    : therapyType;
  const therapyIncluded = listing_type && listing_type?.toLowerCase().includes('therapy');
  if (!therapyIncluded && listing_type) {
    listing_type += ' Therapy';
  }
  return listing_type;
};

const freeBookingItem = (lineItems, listing) => {
  return lineItems?.bookings?.map(lineItem => {
    const itemObj = {
      item_id: listing?.id?.uuid,
      item_name: listing?.attributes?.title || listing?.author?.attributes?.profile?.displayName,
      item_type: listing?.attributes?.publicData?.unitType,
      price: 0,
      quantity: '1',
      item_category: 'Free Booking',
      startDate: lineItem?.attributes?.start,
      endDate: lineItem?.attributes?.end,
    };
    return itemObj;
  });
};

export const listingPageEvent = (items, therapyType) => {
  const categoryName = getTherapyType(therapyType);
  const _items =
    items &&
    items?.length > 0 &&
    items?.map((item, index) => {
      const { attributes = {}, id, type, author } = item;
      const itemObj = {
        item_name: attributes?.title || author?.attributes?.profile?.displayName,
        item_id: id?.uuid,
        price: convertMoneyToNumber(attributes?.price),
        item_brand: 'Therapy is Brown',
        item_category: categoryName || attributes?.title,
        item_list_name: attributes?.title,
        index: index,
        quantity: '1',
      };
      return itemObj;
    });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: _items,
    },
  });
};

export const productDetailEvent = (
  eventName,
  orderData,
  product,
  listType,
  itemCategory,
  isFreeBooking
) => {
  const { Money } = types;
  const { attributes = {}, id, type, author } = product;
  const isViewItem = eventName === 'view_item';
  const isTimeSlotEvent = eventName === 'timeslot_added';
  const _listType = isViewItem ? itemCategory : listType;
  const categoryName = isViewItem
    ? getTherapyType(itemCategory)
    : isTimeSlotEvent
    ? getTherapyType(listType)
    : attributes?.title;
  let qtyTotal = orderData?.[0]?.quantity?.d?.[0] || 0;
  const viewDetailEventPrice = product?.attributes?.publicData?.Prices?.[_listType]
    ? new Money(
        product?.attributes?.publicData?.Prices?.[_listType],
        product?.attributes?.price?.currency
      )
    : attributes?.price;

  const price = product?.attributes?.publicData?.Prices?.[listType]
    ? new Money(
        product?.attributes?.publicData?.Prices?.[listType],
        orderData?.[0]?.unitPrice?.currency
      )
    : attributes?.price;

  window.dataLayer = window.dataLayer || [];
  window?.dataLayer?.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: eventName,
    ecommerce: {
      items: [
        {
          item_name: attributes?.title || author?.attributes?.profile?.displayName,
          item_id: id?.uuid,
          price: isFreeBooking
            ? 0
            : convertMoneyToNumber(isViewItem ? viewDetailEventPrice : price),
          item_brand: 'Therapy is Brown',
          item_category: isFreeBooking ? 'Free Booking' : categoryName,
          quantity: isFreeBooking ? '1' : qtyTotal || '1',
        },
      ],
    },
  });
};

export const addToCartEvent = (eventName, orderData, listing, intl) => {
  const { Money } = types;
  const items = Object.keys(orderData)
    ?.map(listType => {
      if (listType === 'totalPricing') return null;
      const lineItemArray = orderData?.[listType];
      if (lineItemArray?.freeBooking) {
        return freeBookingItem(lineItemArray, listing);
      }
      return lineItemArray?.bookings?.map(lineItem => {
        let qtyTotal = lineItem?.[0]?.quantity?.d?.[0] || 0;
        const price = listing?.attributes?.publicData?.Prices?.[listType]
          ? new Money(
              listing?.attributes?.publicData?.Prices?.[listType],
              lineItem?.[0]?.unitPrice?.currency
            )
          : listing?.attributes?.price;
        const itemObj = {
          item_id: listing?.id?.uuid,
          item_name:
            listing?.attributes?.title || listing?.author?.attributes?.profile?.displayName,
          item_type: listing?.attributes?.publicData?.unitType,
          price: convertMoneyToNumber(price),
          quantity: qtyTotal,
          item_category: getTherapyType(listType) || listing?.attributes?.title,
          startDate: lineItem?.attributes?.start,
          endDate: lineItem?.attributes?.end,
        };
        return itemObj;
      });
    })
    .filter(Boolean);

  const modifiedItems = items?.map(item => {
    return item?.[0];
  });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      items: modifiedItems,
    },
  });
};

export const beginCheckoutEvent = (orderData, listing, intl) => {
  const { Money } = types;
  const lineItemsArray = orderData?.allLineItem;
  const items = Object.keys(lineItemsArray)
    ?.map(listType => {
      if (listType === 'totalPricing') return null;
      const lineItems = lineItemsArray?.[listType];
      if (lineItems?.freeBooking) {
        return freeBookingItem(lineItems, listing);
      }

      return lineItems?.bookings
        ?.map(lineItem => {
          let qtyTotal = lineItem?.[0]?.quantity?.d?.[0] || 0;
          const price = listing?.attributes?.publicData?.Prices?.[listType]
            ? new Money(
                listing?.attributes?.publicData?.Prices?.[listType],
                lineItem?.[0]?.unitPrice?.currency
              )
            : listing?.attributes?.price;
          const itemObj = {
            item_id: listing?.id?.uuid,
            item_name:
              listing?.attributes?.title || listing?.author?.attributes?.profile?.displayName,
            item_type: listing?.attributes?.publicData?.unitType,
            price: convertMoneyToNumber(price),
            quantity: qtyTotal,
            item_category: getTherapyType(listType) || listing?.attributes?.title,
            startDate: lineItem?.attributes?.start,
            endDate: lineItem?.attributes?.end,
          };
          return itemObj;
        })
        ?.filter(Boolean);
    })
    .filter(Boolean);
  const modifiedItems = items?.map(item => {
    return item?.[0];
  });
  window.dataLayer = window?.dataLayer || [];
  window.dataLayer?.push({
    event: 'begin_checkout',
    userId: listing?.author?.id?.uuid,
    ecommerce: {
      items: modifiedItems,
    },
  });
};

export const loginSignUpEvent = (eventName, userId, userIdkey) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    [userIdkey]: userId,
    ...(eventName === 'login' && { method: 'Email' }),
  });
};

export const purchaseEvent = (currentUser, transaction, allLineItems) => {
  const { Money } = types;

  const items = Object.keys(allLineItems)
    ?.map(listType => {
      if (listType === 'totalPricing') return null;
      const lineItems = allLineItems?.[listType];
      if (lineItems?.freeBooking) {
        return freeBookingItem(lineItems, transaction?.listing);
      }
      return lineItems?.bookings?.map(lineItem => {
        let qtyTotal =
          lineItem?.[0]?.quantity?.d?.[0] || Number(lineItem?.[0]?.quantity?.decimal) || 0;
        const price = new Money(
          lineItem?.[0]?.unitPrice?.amount,
          lineItem?.[0]?.unitPrice?.currency
        );

        const itemObj = {
          item_id: lineItem?.[0]?.listingId?.uuid,
          item_name:
            transaction?.listing?.attributes?.title ||
            transaction?.listing?.author?.attributes?.profile?.displayName,
          item_type: lineItem?.[0]?.code.split('/')?.[1],
          price: convertMoneyToNumber(price),
          quantity: qtyTotal,
          item_category: getTherapyType(listType) || transaction?.listing?.attributes?.title,
          startDate: lineItem?.attributes?.start,
          endDate: lineItem?.attributes?.end,
        };
        return itemObj;
      });
    })
    .filter(Boolean);

  const modifiedItems = items?.map(item => {
    return item?.[0];
  });
  const currency = transaction?.listing?.attributes?.price?.currency;
  const totalPrice = new Money(allLineItems?.totalPricing?.bookingAmountTotal, currency);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    userId: currentUser?.id?.uuid,
    ecommerce: {
      currency: transaction?.listing?.attributes?.price?.currency,
      value: convertMoneyToNumber(totalPrice),
      transaction_id: transaction?.id?.uuid,
      items: modifiedItems,
    },
  });
};

import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, pricing, totalQty, isFree, coupDataOnConfirmation } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isFree
    ? 'OrderBreakdown.free'
    : isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';

  // const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  // const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  // const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  // const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  let quantity =
    totalQty ||
    (unitPurchase && unitPurchase?.pricing
      ? pricing?.totalQuantity?.toString()
      : unitPurchase?.quantity?.d?.[0] || null);

  const storedUnitPrice = unitPurchase?.originalPrice?.unitPrice
    ? formatMoney(intl, new Money(unitPurchase?.originalPrice?.unitPrice,  unitPurchase?.unitPrice?.currency))
    : null;

  const priceIfCoupon = coupDataOnConfirmation?.originalLineItemPrice
    ? formatMoney(
        intl,
        new Money(coupDataOnConfirmation?.originalLineItemPrice, unitPurchase?.unitPrice?.currency)
      )
    : null;

  const priceWithoutCoupon = unitPurchase?.unitPrice
    ? formatMoney(intl, unitPurchase?.unitPrice)
    : null;

  const unitPrice = storedUnitPrice || priceIfCoupon || priceWithoutCoupon;

  // const total = unitPurchase?.lineTotal ? formatMoney(intl, unitPurchase?.lineTotal) : null;

  if (isFree) {
    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id={translationKey} values={{ unitPrice: 'Free', quantity: '15' }} />
        </span>
        {/* <span className={css.itemValue}>{total}</span> */}
      </div>
    );
  }

  return quantity && unitPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      {/* <span className={css.itemValue}>{total}</span> */}
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;

import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';
import { Form } from 'react-final-form';

import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  Heading,
  FieldRadioButton,
  FieldCheckbox,
  InlineTextButton,
  NamedRedirect,
  FieldToggle,
  FieldCurrencyInput,
} from '../../components';

import css from './OrderPanel.module.css';
import SectionTextMaybe from '../../containers/ListingPage/SectionTextMaybe';
const { types } = require('sharetribe-flex-sdk');

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};
const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const { price, publicData, validListingTypes, intl } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  return (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    listingConfig,
    setSelectedTimeSlot,
    saveAllLineItems,
    currentUser,
  } = props;
  const [selectedListingType, setSelectedListingType] = useState(null);
  const [selectedListingTypeKey, setSelectedListingTypeKey] = useState(null);
  const [isFreeBookingSelected, setIsFreeBookingSelected] = useState(false);
  const [isPrebookedSelected, setIsPrebookedSelected] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { Money } = types;
  const publicData = listing?.attributes?.publicData || {};
  const { unitType, transactionProcessAlias = '', Prices } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const price =
    (selectedListingTypeKey &&
      Prices?.[selectedListingTypeKey] &&
      new Money(Prices?.[selectedListingTypeKey], listing?.attributes?.price?.currency)) ||
    listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;
  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
  const showPriceMissing = !isPrebookedSelected && isPaymentProcess && Number.isNaN(price);
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm =
    !isPrebookedSelected && shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  // const titleClasses = classNames(titleClassName || css.orderTitle);

  const freeBookingEnabled = listingConfig?.listingFields?.reduce((element, config) => {
    const { key, scope = 'public' } = config;
    if (key === 'Free') {
      return scope === 'public' ? publicData[key] === 'true' : null;
    }
  }, []);

  const selectedListTypeHasFreeBooking =
    currentUser?.attributes?.profile?.publicData?.freeListings?.[listing?.id?.uuid]?.used;
  const userFreeBookingStatus =
    !currentUser ||
    currentUser?.attributes?.profile?.publicData?.freeListings?.[listing?.id?.uuid]?.status;
  const isLoggedIn = currentUser;

  const signUp = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={() => setRedirect(true)}>
      <FormattedMessage id="OrderPanel.signUp" />
    </InlineTextButton>
  );
  const isOrderPage = typeof window !== 'undefined' && window.location.href.includes('/order/');
  const authorFullName = listing?.attributes?.title;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => {
          closeOrderModal(history, location);
        }}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {isOrderOpen && authorDisplayName ? (
          <div className={css.modalHeading}>
            <H1 className={css.heading}>{authorDisplayName}</H1>
          </div>
        ) : null}

        <div className={css.orderHeading}>
          <div className={css.author}>
            {isOrderPage ? null : (
              <AvatarSmall
                authorFullName={authorFullName}
                user={author}
                className={css.providerAvatar}
              />
            )}
            <span className={css.providerNameLinked}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
            </span>

            <span className={css.providerNamePlain}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
            </span>
          </div>
          {/* {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>} */}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>
        {!isFreeBookingSelected && (
          <PriceMaybe
            price={price}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
          />
        )}

        {listingConfig?.listingFields?.reduce((pickedElements, config) => {
          const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;

          if (key !== 'FiltersID') return pickedElements;
          const listingType = publicData?.listingType;
          const isTargetListingType =
            includeForListingTypes == null || includeForListingTypes.includes(listingType);

          const value =
            scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
          const hasValue = value != null;
          return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
            ? [
                ...pickedElements,
                <Form
                  onSubmit={e => {}}
                  render={() => {
                    const options = createFilterOptions(enumOptions);
                    const listingType =
                      typeof location !== 'undefined' &&
                      new URLSearchParams(location.search).get('listingType') !== 'undefined'
                        ? new URLSearchParams(location.search).get('listingType')?.replace('-', ' ')
                        : null;

                    const [selected, setSelected] = useState(listingType);
                    if (listingType && !selectedListingType) {
                      setSelectedListingType(listingType);
                      setSelectedListingTypeKey(listingType);
                    }
                    const matchedTypes = options.filter(option =>
                      value?.some(value => option.key === value)
                    );
                    return (
                      <>
                        <div className={css.wrapper}>
                          <FieldToggle
                            id="prebooked"
                            name="prebooked"
                            label={intl.formatMessage({
                              id: 'PreBooked.toggleText',
                            })}
                            onChange={value => {
                              setIsPrebookedSelected(!isPrebookedSelected);
                              setSelectedTimeSlot([]);
                              saveAllLineItems({});
                            }}
                          />
                        </div>
                        {!isPrebookedSelected && freeBookingEnabled && (
                          <div className={css.wrapper}>
                            <Heading
                              as="h2"
                              rootClassName={classNames(css.freeBookingHeading, {
                                [css.disabled]: userFreeBookingStatus,
                              })}
                            >
                              {intl.formatMessage({
                                id: 'FreeBooking.heading',
                              })}
                            </Heading>
                            <FieldCheckbox
                              className={css.sameAddressCheckbox}
                              textClassName={classNames(css.checkboxLabel, {
                                [css.disabled]: userFreeBookingStatus,
                              })}
                              id="Free"
                              name="free_booking"
                              label={intl.formatMessage({
                                id: 'FreeBooking.checkboxText',
                              })}
                              value={'True'}
                              // useSuccessColor
                              checked={isFreeBookingSelected}
                              disabled={userFreeBookingStatus}
                              onChange={value => {
                                if (userFreeBookingStatus) return;
                                setIsFreeBookingSelected(!isFreeBookingSelected);
                                setSelectedTimeSlot([]);
                                saveAllLineItems({});
                              }}
                            />

                            {userFreeBookingStatus === 'pending' ? (
                              <span className={css.freeBookingMessage}>
                                {intl.formatMessage({
                                  id: 'FreeBooking.pending',
                                })}
                              </span>
                            ) : null}
                            {userFreeBookingStatus === 'accepted' ? (
                              <span className={css.freeBookingMessage}>
                                {intl.formatMessage({
                                  id: 'FreeBooking.accepted',
                                })}
                              </span>
                            ) : null}
                            {!currentUser ? (
                              <div className={css.freeBookingSignUp}>
                                <FormattedMessage
                                  id="FreeBooking.login"
                                  values={{
                                    signUp,
                                  }}
                                />
                              </div>
                            ) : null}
                            {redirect && (
                              <NamedRedirect
                                name="SignupPage"
                                state={{
                                  from: `${location.pathname}${location.search}${location.hash}`,
                                }}
                              />
                            )}
                          </div>
                        )}

                        <div className={css.wrapper}>
                          <Heading as="h2" rootClassName={css.sectionHeading}>
                            Select Type of Therapy
                          </Heading>
                          <div className={options.length > 5 ? css.twoColumns : null}>
                            {matchedTypes?.map((option, index) => {
                              const formattedKey = option?.key?.replace('-', ' ');
                              if (!value?.includes(option?.key)) return null;

                              if (!selected && index === 0) {
                                let listingType =
                                  typeof location !== 'undefined' &&
                                  new URLSearchParams(location.search).get('listingType') !==
                                    'undefined'
                                    ? new URLSearchParams(location.search).get('listingType')
                                    : null;

                                setSelectedListingType(listingType || option?.label);
                                setSelectedListingTypeKey(listingType || formattedKey);
                                setSelected(listingType || formattedKey);
                              }

                              return (
                                <FieldRadioButton
                                  id={option?.label}
                                  name="listing_type"
                                  label={option?.label}
                                  value={option?.label}
                                  // checkedClassName={css.checkedAvailable}
                                  // showAsRequired={index === 0}
                                  checked={formattedKey === selected}
                                  onChange={() => {
                                    setSelected(formattedKey);
                                    setSelectedListingType(option?.label);
                                    setSelectedListingTypeKey(formattedKey);
                                  }}
                                  disabled={!value.includes(option?.key)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                    );
                  }}
                ></Form>,
              ]
            : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
            ? [
                ...pickedElements,
                <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
              ]
            : pickedElements;
        }, [])}

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            currentlisting={listing}
            selectedListingType={selectedListingTypeKey}
            isFreeBookingSelected={isFreeBookingSelected}
            isPrebookedSelected={isPrebookedSelected}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          <div key={formattedPrice} className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div>
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location
            )}
            disabled={isOutOfStock}
          >
            {isBooking ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : isPurchase ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
            )}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: [],
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,
  selectedListingType: string,
  saveAllLineItems: func,
  setSelectedTimeSlot: func,
  saveAllLineItems: func,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);

/* eslint-disable no-console */
import React from 'react';
import { Form, Field } from 'react-final-form';
import * as validators from '../../util/validators';
import css from './FinancialHardship.module.css';
import { sendFinancialHardshipForm } from '../../util/api';
import { PrimaryButton } from '../Button/Button';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import { useIntl } from 'react-intl';

const FinancialHardshipForm = ({ onModalClose }) => {
  const intl = useIntl();
  const onSubmit = async values => {
    const response = await sendFinancialHardshipForm({ formValues: values });
    setTimeout(() => {
      response?.message && (onModalClose && onModalClose());
    }, 1000);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting, pristine, submitSucceeded }) => {
          const submitDisabled = invalid || pristine || submitting;
          const emailRequired = validators.required(
            intl.formatMessage({
              id: 'FinancialHardshipForm.emailRequired',
            })
          );
          const emailValid = validators.emailFormatValid(
            intl.formatMessage({
              id: 'FinancialHardshipForm.emailInvalid',
            })
          );

          return (
            <form onSubmit={handleSubmit}>
              <div className={css.field_wrapper}>
                <FieldTextInput
                  type="text"
                  id={'username'}
                  name="username"
                  autoComplete="username"
                  label={intl.formatMessage({
                    id: 'FinancialHardshipForm.nameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'FinancialHardshipForm.namePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'FinancialHardshipForm.nameRequired',
                    })
                  )}
                />
              </div>
              <div className={css.field_wrapper}>
                <FieldTextInput
                  type="email"
                  id={'email'}
                  name="email"
                  autoComplete="email"
                  label={intl.formatMessage({
                    id: 'FinancialHardshipForm.emailLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'FinancialHardshipForm.emailPlaceholder',
                  })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>
              <div className={css.field_wrapper}>
                <FieldTextInput
                  type="number"
                  min={0}
                  id={'estimatedAnnualIncome'}
                  name="estimatedAnnualIncome"
                  autoComplete="estimatedAnnualIncome"
                  label={intl.formatMessage({
                    id: 'FinancialHardshipForm.EstimatedAnnualIncomeLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'FinancialHardshipForm.EstimatedAnnualIncomePlaceholder',
                  })}
                  parse={value => {
                    const parsed = Number.parseInt(value, 10);
                    return Number.isNaN(parsed) ? null : parsed;
                  }}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'FinancialHardshipForm.EstimatedAnnualIncomeRequired',
                    })
                  )}
                />
              </div>
              <div className={css.field_wrapper}>
                <FieldTextInput
                  type="text"
                  id={'DependentsAndResp'}
                  name="DependentsAndResp"
                  autoComplete="DependentsAndResp"
                  label={intl.formatMessage({
                    id: 'FinancialHardshipForm.DependentsAndResponsibilitiesLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'FinancialHardshipForm.DependentsAndResponsibilitiesPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'FinancialHardshipForm.DependentsAndResponsibilitiesRequired',
                    })
                  )}
                />
              </div>
              <div className={css.field_wrapper}>
                <FieldTextInput
                  type="textarea"
                  id={'message'}
                  name="message"
                  autoComplete="message"
                  label={intl.formatMessage({
                    id: 'FinancialHardshipForm.messageLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'FinancialHardshipForm.messagePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'FinancialHardshipForm.messageRequired',
                    })
                  )}
                />
              </div>
              <div className={`${css.submit_button} ${submitDisabled ? css.disable_btn : ''}`}>
                <PrimaryButton
                  type="submit"
                  inProgress={submitting}
                  disabled={submitDisabled || submitSucceeded}
                  ready={submitSucceeded}
                >
                  {intl.formatMessage({
                    id: 'FinancialHardshipForm.submitButtonText',
                  })}
                </PrimaryButton>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default FinancialHardshipForm;
